<template>
    <div id="config-discount" class="container-fluid">
        <div class="row pb-4">
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">

                    <div class="col-12 px-0 py-3 bg-lightgray my-2">
                        <div class="row p-0">
                            <div class="col-6 col-xl-4 opensans-bold">
                                <ki-input @keyup.enter="navigate"
                                    label="Buscar.."
                                    placeholder="Ej: promoción..."
                                    v-model="search_query"
                                    full>
                                </ki-input>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 py-3" v-if="loading">
                        <ki-loader></ki-loader>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="empty_search">
                        <p>
                            No existen elementos con esos párametros de búsqueda
                        </p>
                    </div>

                    <div class="col-12 p-0 mt-2" v-if="!loading && !empty_search">
                        <ki-table :data="data_table" 
                            :allow_headers="headers_allow" 
                            no_pagination color_headers="white" color_body="white">
                            <template v-slot:cashbox_open="data">
                                {{ new Date(data.item.cashbox_open).toLocaleString() }}
                            </template>
                            <template v-slot:cashbox_close="data">
                                {{ data.item.cashbox_close ? new Date(data.item.cashbox_close).toLocaleString() : '-' }}
                            </template>
                            <template v-slot:amount="data">
                                {{ data.item.amount ? `$ ${formatAmount(data.item.amount, 2)}` : '-' }}
                            </template>
                        </ki-table>
                    </div>

                    <!--- Paginator ----->
                    <div class="col-12 p-0 m-0 mt-2">
                        <div class="row justify-content-between align-items-center text-dark">
                            <div class="col d-flex">
                                <label class="mr-2">cajas por página: </label>
                                <ki-input @keyup.enter="navigate"
                                    v-model="rows"
                                    :disabled="current_page > 1">
                                </ki-input>
                            </div>

                            <div class="col-1  d-flex justify-content-end p-0">
                                <button @click.stop="prev"
                                    class="btn btn-primary text-white pills"
                                    type="button"
                                    :disabled="current_page == 1">
                                    <b-icon icon="arrow-left"
                                    variant="white">
                                    </b-icon>
                                </button>
                            </div>

                            <div class="col-1" style="max-width: 80px;">
                                <ki-input @keyup.enter="navigate"
                                    v-model="current_page"
                                    full>
                                </ki-input>
                            </div>

                            <div class="col-1">
                                <p class="m-0">
                                    de {{  total_pages  }}
                                </p>
                            </div>

                            <div class="col-1 p-0" style="max-width: 65px">
                                <button @click.stop="next"
                                    class="btn btn-primary text-white pills"
                                    :disabled="current_page == total_pages"
                                    type="button">
                                    <b-icon icon="arrow-right"
                                        variant="white">
                                    </b-icon>
                                </button>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    //helpers
    import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            headers_allow: [
                { key: 'store_name', label: "Sucursal" }, 
                { key: 'dc_name', label: "Almacen" },
                { key: 'cashbox_open', label: "Apertura"},
                { key: 'cashbox_close', label: "Cierre"},
                { key: 'amount', label: "Total recaudado"}
            ],
            data_table: [],
            loading: true,
            loading_error: null,
            empty_search: false,

            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            total_pages: 'reports/getTotalPages',
            total_rows: 'reports/getTotalRows',
            cashboxes: 'reports/getCollection'
        })
    },
    methods: {
        //paginator
        async navigate(){
            this.empty_search = false
            if(this.rows > this.total_rows) {
                this.rows = this.total_rows == 0 ? 10 : this.total_rows;

            }

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

	        await this.$store.dispatch('reports/listCashboxHistory', { page: this.current_page, rows: this.rows, search: this.search_query})
            
            if(!this.cashboxes) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.cashboxes
            }
	        

        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted() {
        try {
            await this.navigate()
            this.loading = false
        }

        catch(e) {
            this.loading = false
            this.loading_error = this.getError
        }
        
    }
};
</script>