<template>
    <div id="Buyorder_Gestor" class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box">
        <div class="row my-3">

            <!--gray navbar-->
            <div class="col-12 bg-lightgray p-0">
                <div class="row align-items-end py-3">
                    <div class="col-6 col-sm-3 opensans-bold">
                        <ki-input @input="navigate"
                                  label="Buscar:"
                                  placeholder="Ej: lala"
                                  type="text"
                                  v-model="search_query"
                                  full>
                        </ki-input>
                    </div>
                    <div class="col-6 col-sm-3 opensans-bold">
                        <ki-input @change="navigate"
                                  label="Fecha:"
                                  type="date"
                                  v-model="date"
                                  full>
                        </ki-input>
                    </div>
                    <div class="col-6 col-sm-3 opensans-bold">
                        <ki-select @change="navigate"
                                   label="Proveedor:"
                                   class="opensans-bold"
                                   v-model="id_vendor"
                                   :options="provider_options"
                                   full>
                        </ki-select>
                    </div>
                    <div class="col-6 col-sm-3 opensans-bold">
                        <ki-select @change="navigate"
                                   label="Producto:"
                                   class="opensans-bold"
                                   v-model="id_product"
                                   :options="products_options"
                                   full>
                        </ki-select>
                    </div>
                    <div class="col-6 col-sm-3 opensans-bold mt-3">
                        <button
                            class="btn btn-white pills"
                            type="button">
                            Buscar
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12 my-2" v-if="loading">
                <ki-loader></ki-loader>
            </div>

            <div class="col-12 my-2" v-if="!loading && fetch_error">
                <p class="opensans-bold text-dark text-center">
                    Un error ha ocurrido intente más tarde
                </p>
            </div>

            <div class="col-12 my-2" style="max-height: 400px; overflow-y: scroll" v-if="!loading && !fetch_error && order_table_data.length > 0">
                <ki-table :data="order_table_data"
                          :allow_headers="order_table_headers"
                          no_pagination color_body="white" color_headers="primary" border_headers="primary" color_headers_text="white"
                          full>
                    <template v-slot:Details="data">
                        <button @click.stop="viewOrder(data.item)"
                                class="btn btn-white pills"
                                type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:order_status="data">
                        {{ data.item.order_status == 'waiting_delivery' ? 'En espera' : 'Entregado' }}
                    </template>
                    <template v-slot:Borrar="data">
                        <div v-if="data.item.order_status === 'waiting_delivery'" class="mt-1">
                            <button @click.stop="selectOrderToDelete(data.item)"
                                    class="btn p-0 pills"
                                    style="width: 50px; height:30px;"
                                    type="button">
                                <img :src="trash_icon_red"/>
                            </button>
                        </div>
                        <div v-else>

                        </div>
                    </template>
                </ki-table>
            </div>

        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página:
                    </label>
                    <ki-input @keyup.enter="navigate"
                              v-model="rows"
                              :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                            class="btn btn-primary text-white pills"
                            type="button"
                            :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                                variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                              v-model="current_page"
                              full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                            class="btn btn-primary text-white pills"
                            :disabled="current_page == total_pages"
                            type="button">
                        <b-icon icon="arrow-right"
                                variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!---Modal----->
        <ki-modal v-if="order_detail_modal">
            <div class="row bg-white position-relative" style="border-radius: 15px">
                <button @click.stop="order_detail_modal = false"
                        class="btn btn-white p-0 position-absolute"
                        style="width: 25px; height: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2); top: -10px; right: -10px"
                        type="button">
                    X
                </button>
                <div class="col-12 my-2 opensans-bold">
                    <p class="text-dark">
                        Detalle de la orden
                    </p>
                </div>

                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3 pt-3" style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Proveedor:
                            </p>
                            <span>
                                {{ resume.provider }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Cantidad de productos:
                            </p>
                            <span>
                                {{ resume.products.length}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Fecha de pedido:
                            </p>
                            <span>
                                {{ new Date(resume.date).toLocaleString() }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Fecha de entrega:
                            </p>
                            <span>
                                {{ new Date(resume.delivery_date).toLocaleString() }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0">
                    <div class="row p-0 bg-lightgray mx-3 mb-3" style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                        <div class="col-7 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Forma de pago:
                            </p>
                            <span>
                                {{  resume.transaction_type }}
                            </span>
                        </div>

                        <div class="col-5 text-dark d-flex">
                            <p class="opensans-semibold mr-1">
                                Terminos:
                            </p>
                            <span>
                                {{ resume.comments}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <ki-table :data="resume.products"
                              :allow_headers="details_table_headers"
                              no_pagination color_body="white" color_headers="primary"
                              color_headers_text="white" border_headers="primary">
                    </ki-table>
                </div>

                <div class="col-12 mt-2">
                    <div class="row opensans-bold bg-lightgray p-2" style="border-radius: 10px;">

                        <div class="col-2 opensans-semibold">
                            <ki-input label="Subtotal:"
                                      type="number"
                                      v-model="resume.subtotal"
                                      :disabled="true"
                                      full>
                            </ki-input>
                        </div>

                        <div class="col-2 opensans-semibold">
                            <ki-input label="Importe:"
                                      type="number"
                                      v-model="resume.taxes"
                                      :disabled="true"
                                      full>
                            </ki-input>
                        </div>

                        <div class="col-2 opensans-semibold">
                            <ki-input label="Total:"
                                      type="number"
                                      v-model="resume.subtotal"
                                      :disabled="true"
                                      full>
                            </ki-input>
                        </div>

                    </div>
                </div>

                <div class="col-12 py-3 d-flex justify-content-end">
                    <button @click.stop="order_detail_modal = false"
                            class="btn btn-primary pills text-white"
                            type="button">
                        Cerrar
                    </button>
                </div>

            </div>
        </ki-modal>

        <ki-modal v-if="complete_order_modal">
            <div class="row bg-white position-relative p-3" style="border-radius: 15px;">
                <button @click.stop="complete_order_modal = false; selected_id = null"
                        class="btn btn-white position-absolute p-0"
                        style="top: -10px; right: -10px; width: 35px; height: 35px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2)"
                        type="button">
                    X
                </button>
                <div class="col-12 text-dark opensans-bold text-center">
                    <p>
                        ¿Marcar la orden de compra como entregada?.
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="complete_order_modal = false; selected_id = null"
                            class="btn btn-white pills"
                            type="button">
                        Cancelar
                    </button>
                    <button @click.stop="completeOrder"
                            class="btn btn-primary text-white pills ml-4"
                            type="button">
                        Aceptar
                    </button>
                </div>
            </div>
        </ki-modal>

        <ki-modal v-if="delete_order_modal">
            <div class="row bg-white position-relative p-3 text-center" style="border-radius: 15px;">
                <button @click.stop="selected_id = false; delete_order_modal = false"
                        class="btn btn-white p-0 position-absolute"
                        style="width: 25px; height: 25px; top: -10px; right: -10px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                        type="button">
                    X
                </button>
                <div class="col-12 text-dark opensans-bold">
                    <p>
                        ¿Cancelar orden de compra?. <br>
                        Esta operación no puede ser revertida
                    </p>
                </div>

                <div class="col-12">
                    <button @click.stop="delete_order_modal = false; selected_id = false"
                            class="btn btn-white pills"
                            type="button">
                        Cancelar
                    </button>
                    <button @click.stop="deleteOrder"
                            class="btn btn-danger pills text-white ml-2"
                            type="button">
                        Eliminar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
//icons
import trash_icon_red from 'ASSETS/icons/icon_delete_red.png'
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            trash_icon_red,
            complete_order_modal: false,
            delete_order_modal: false,
            order_table_data: [],
            order_table_headers: [
                {key: 'id_purchase_order', label: 'No. Orden'},
                {key: 'vendor_name', label: 'Proveedor'},
                {key: 'transaction_name', label: 'Forma de pago'},
                {key: 'Details'},
                {key: 'order_status', label:'Estado'},
            ],
            details_table_data: [],
            details_table_headers: [
                {key: 'quantity', label: 'Cantidad'},
                {key: 'product_name', label: 'Producto'},
            ],
            order_detail_modal: false,
            is_filtered: false,
            resume: {
                provider: null,
                date: null,
                delivery_date: null,
                transaction_type: null,
                comments: null,
                subtotal: null,
                total: null,
                taxes: null,
                products: []
            },
            loading: true,
            fetch_error: false,
            selected_id: null,
            //paginator
            current_page: 1,
            rows: 10,
            search_query: '',
            date: '',
            id_vendor: 0,
            id_product: 0,
            provider_options: [],
            products_options: []
        };
    },
    computed: {
        ...mapGetters({
            orders: 'buy_order_module/getOrders',
            order: 'buy_order_module/getOrder',
            total_pages: 'buy_order_module/getTotalPages',
            total_rows: 'buy_order_module/getTotalRows',
            providers: 'providers/getProviders',
            products: 'products_module/getProducts',
        })
    },
    methods: {
        async viewOrder(order) {
            let id = order.id_purchase_order
            await this.$store.dispatch('buy_order_module/viewOrder', { id_purchase_order: id})

            console.log('details:', this.order)
            this.resume.comments = this.order.delivery_conditions
            this.resume.date = this.order.creation
            this.resume.delivery_date = this.order.delivery_date
            this.resume.products = this.order.details.map( product => {
                return {
                    'product_name': product.product_name,
                    'quantity': product.quantity
                }
            })

            this.resume.transaction_type = this.order.id_transaction_type
            this.resume.provider = this.order.id_vendor
            this.resume.subtotal = this.order.subtotal
            this.resume.taxes = this.order.tax
            this.resume.total = this.order.total

            this.order_detail_modal = true

        },
        selectOrderToComplete(order) {
            this.selected_id = order.id_purchase_order
            this.complete_order_modal = true
        },
        selectOrderToDelete(order) {
            this.selected_id = order.id_purchase_order
            this.delete_order_modal = true
        },
        async completeOrder() {

            let id = this.selected_id
            await this.$store.dispatch('buy_order_module/completeOrder', { id_purchase_order: id})
            await this.navigate();

            this.complete_order_modal = false
            this.selected_id = false
        },
        async deleteOrder() {

            let id = this.selected_id
            await this.$store.dispatch('buy_order_module/deleteOrder', { id_purchase_order: id})
            await this.navigate()

            this.delete_order_modal = false
            this.selected_id = false

        },
        //paginator
        navigate: async function () {
            this.empty_search = false
            this.loading = true

            if (this.current_page > this.total_pages) {
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }


            await this.$store.dispatch('buy_order_module/listOrders', {
                datatable: true,
                page: this.current_page,
                rows: this.rows,
                search: this.search_query,
                pending: this.is_filtered,
                date: this.date,
                id_vendor: this.id_vendor,
                id_product: this.id_product
            })


            if (!this.orders) {
                this.empty_search = true;
                return
            } else {
                this.order_table_data = this.orders.map(order => {
                    return {...order, Details: '-', Estado: '-'}
                })
            }

            console.log(this.order_table_data)

            this.loading = false
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async created() {
        try {

            //orders
            await this.navigate()

            //providers
            await this.$store.dispatch('providers/listProviders', {datatable: false})
            this.provider_options = this.providers.map( provider => {
                return {
                    id: provider.id_vendor,
                    label: provider.vendor_name,
                }
            })

            this.provider_options = [{id: 0, label: 'Seleccione...'}, ...this.provider_options]

            //products
            await this.$store.dispatch('products_module/listProducts', { datable: false })
            this.products_options = this.products.map(product => {
                return {
                    id: product.id_product,
                    label: product.product_name,
                }
            })

            this.products_options = [{id: 0, label: 'Seleccione...'}, ...this.products_options]


            this.loading = false
            this.fetch_error = false

        } catch(error) {
            console.log(error)
            this.loading = false
            this.fetch_error = true
        }
    }
}
</script>

<style lang="scss" scoped>
</style>