<template>
    <div id="reports" class="container-fluid">
        <titles title="Reportes" :subtitle="tab_active" :othertitle="titletab_active"></titles>
        <tabs :array_tabs="tabs_array" @changeTab="controlTabs($event)"></tabs>
        <title-tabs :array_titletabs="titletabs_array" @changeTitle="controlTitles($event)"></title-tabs>

        <div  v-if="tab_active == 'Caja'">
            <cashbox-history v-if="titletab_active == 'Historial de cajas'"></cashbox-history>
            <sales-history v-if="titletab_active == 'Historial de ventas'"></sales-history>
            <ware-changes v-if="titletab_active == 'Cambios de mercancia'"></ware-changes>
        </div>

        <div  v-if="tab_active == 'Inventarios'">
            <transfers v-if="titletab_active == 'Historial de Traspasos'"></transfers>
            <orders v-if="titletab_active == 'Historial de Ordenes'"></orders>
        </div>
    </div>
</template>

<script>
    //Components
    import Titles from '../components/Titles.vue'
    import Tabs from '../components/Tabs.vue'
    import TitleTabs from '../components/TitleTabs.vue'

    //Views
    //CashRegister
    import Boxcut from "./Reports/CashRegister/Boxcut.vue";
    import Warechanges from "./Reports/CashRegister/Warechanges.vue";
    import CashboxHistory from "./Reports/CashRegister/CashboxHistory.vue"
    import SalesHistory from "./Reports/CashRegister/SalesHistory.vue"

    //Inventarios
    import Transfers from './Reports/inventories/Transfers.vue'
    import Orders from './Reports/inventories/Orders.vue'

    //Icons
    import icon_cash from '../assets/icons/icon_cash.png'
    import icon_inventory from '../assets/icons/icon_inventory.png'
    import icon_book from '../assets/icons/icon_book.png'

export default {
    name: 'Reports',
    components: {
            'titles': Titles,
            'tabs': Tabs,
            'title-tabs': TitleTabs,
            'box-cut': Boxcut,
            'ware-changes': Warechanges,
            'cashbox-history': CashboxHistory,
            'sales-history': SalesHistory,
            'transfers': Transfers,
            'orders': Orders
        },
    data() {
        return {
            tabs_array:[
                { img: icon_cash,  name: 'Caja', selected: true, key: 'cashregister' },
                { img: icon_inventory,  name: 'Inventarios', selected: false, key: 'invoicing' },
            ],
            titletabs_array:[],
            title_cashregister: [
                { name: 'Historial de cajas', selected: true},
                { name: 'Historial de ventas', selected: false },
                { name: 'Cambios de mercancia', selected: false },
            ],
            title_invoicing: [
                { name: 'Historial de Ordenes', selected: true },
                { name: 'Historial de Traspasos', selected: false },
            ],
            tab_active: null,
            titletab_active: null
        };
    },
    methods: {
        async controlTabs(event) {
            this.tab_active = event
            for (let i in this.tabs_array) {
                if(this.tabs_array[i].selected){
                    this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
                }
            }
            for (let i in this.titletabs_array) {
                if(this.titletabs_array[i].selected){
                    this.titletab_active = this.titletabs_array[i].name
                }
            }
        },
        async controlTitles(event) {
            this.titletab_active = event
            console.log(this.titletab_active);
        },
    },
    created(){
        for (let i in this.tabs_array) {
            if(this.tabs_array[i].selected){
                this.tab_active = this.tabs_array[i].name
                this.titletabs_array = this[`title_${this.tabs_array[i].key}`]
            }
        }
        for (let i in this.titletabs_array) {
            if(this.titletabs_array[i].selected){
                this.titletab_active = this.titletabs_array[i].name
            }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>