<template>
    <div id="config-discount" class="container-fluid">
        <div class="row pb-4">
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">

                    <div class="col-12 px-0 py-3 bg-lightgray my-2">
                        <div class="row p-0">

                            <div class="col-2 opensans-bold">
                                <ki-input @change="navigate"
                                    label="Fecha inicial"
                                    type="date"
                                    v-model="init_date"
                                    full>
                                </ki-input>
                            </div>

                            <div class="col-2  opensans-bold">
                                <ki-input @change="navigate"
                                    label="Fecha Final"
                                    type="date"
                                    v-model="end_date"
                                    full>
                                </ki-input>
                            </div>

                            <div class="col-2">
                                <ki-select @change="filterOptions"
                                    label="Filtrar por:"
                                    class="opensans-bold"
                                    :options="[{id: '', label: 'Seleccione'},{id: 'clients', label: 'Clientes'},{id: 'vendors', label: 'Proveedor'},{id: 'category', label: 'Categoria'},{id: 'subcategory', label: 'Subcategoria'},{id: 'product', label: 'Producto'},]"
                                    v-model="filter_type"
                                    full/>
                            </div>

                            <div class="col-2 d-flex align-items-end" v-if="options.length > 0">
                                <ki-select @change="navigate"
                                    class="opensans-bold w-100"
                                    :options="options"
                                    v-model="id_type"
                                    full/>
                            </div>
                            
                            <!-- <div class="col-6 col-xl-4 opensans-bold">
                                <ki-input @keyup.enter="navigate"
                                    label="Buscar.."
                                    placeholder="Ej: promoción..."
                                    v-model="search_query"
                                    full>
                                </ki-input>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-12 py-3" v-if="loading">
                        <ki-loader></ki-loader>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="empty_search">
                        <p>
                            No existen elementos con esos párametros de búsqueda
                        </p>
                    </div>

                    <div class="col-12 text-dark text-center opensans-bold" v-if="!loading && !empty_search && data_table.length == 0">
                        <p>
                            No existen elementos para la fecha indicada
                        </p>
                    </div>

                    <div class="col-12 p-0 mt-2" v-if="!loading && !empty_search && data_table.length > 0">
                        <ki-table :data="data_table" 
                            :allow_headers="headers_allow" 
                            no_pagination color_headers="white" color_body="white">
                            <template v-slot:iva="data">
                                $ {{data.item.iva }}
                            </template>
                            <template v-slot:subtotal="data">
                                $ {{data.item.subtotal}}
                            </template>
                            <template v-slot:total="data">
                                $ {{data.item.total}}
                            </template>
                            <template v-slot:creation="data">
                                {{ new Date(data.item.creation).toLocaleDateString() }}
                            </template>
                            <template v-slot:type="data">
                                {{ data.item.type == 'sale' ? 'Venta' : data.item.type == 'reserve' ? 'Apartado' : data.item.type == 'sale-pricing' ? 'Cotización de venta' : data.item.type == 'reserve-pricing' ? 'Apartado con cotización' : '' }}
                            </template>
                            <template v-slot:details="data">
                                <button  @click.stop="checkDetails(data.item.id_sale)"
                                    class="btn btn-pill btn-primary p-0 px-3 mb-1"
                                    type="button">
                                    Detalle
                                </button>
                            </template>
                        </ki-table>
                    </div>

                    <!--- Paginator ----->
                    <div class="col-12 p-0 m-0 mt-2" v-if="!loading && !empty_search && data_table.length > 0">
                        <div class="row justify-content-between align-items-center text-dark">
                            <div class="col d-flex">
                                <label class="mr-2">ventas por página: </label>
                                <ki-input @keyup.enter="navigate"
                                    v-model="rows"
                                    :disabled="current_page > 1">
                                </ki-input>
                            </div>

                            <div class="col-1  d-flex justify-content-end p-0">
                                <button @click.stop="prev"
                                    class="btn btn-primary text-white pills"
                                    type="button"
                                    :disabled="current_page == 1">
                                    <b-icon icon="arrow-left"
                                    variant="white">
                                    </b-icon>
                                </button>
                            </div>

                            <div class="col-1" style="max-width: 80px;">
                                <ki-input @keyup.enter="navigate"
                                    v-model="current_page"
                                    full>
                                </ki-input>
                            </div>

                            <div class="col-1">
                                <p class="m-0">
                                    de {{  total_pages  }}
                                </p>
                            </div>

                            <div class="col-1 p-0" style="max-width: 65px">
                                <button @click.stop="next"
                                    class="btn btn-primary text-white pills"
                                    :disabled="current_page == total_pages"
                                    type="button">
                                    <b-icon icon="arrow-right"
                                        variant="white">
                                    </b-icon>
                                </button>
                            </div>

                        </div>
                    </div>

                    <!--new password modal-->
                    <ki-modal v-if="detail_modal">
                        <div class="row bg-white text-dark position-relative" style="border-radius: 10px">
                            <button @click.stop="detail_modal = false"
                                class="btn btn-white p-0 position-absolute"
                                style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.2)"
                                type="button">
                                X
                            </button>

                            <div class="col-12 py-2">
                                <h5 class="opensans-bold">
                                    Detalles de venta
                                </h5>
                            </div>
                            
                            <!--Sale detail-->
                            <div class="col-12 ">
                                <div class="row p-2 rounded bg-lightgray">
                                    <div class="col-6">
                                        <span><strong>ID:</strong> {{ details.sale_data[0].id_sale}}</span>
                                    </div>
                                    <div class="col-6">
                                        <span><strong>Cliente:</strong> {{ details.sale_data[0].client_name }}</span>
                                    </div>
                                    <div class="col-6">
                                        <span><strong>Tienda:</strong> {{ details.sale_data[0].store_name }}</span>
                                    </div>
                                    <div class="col-6">
                                        <span>
                                            <strong>
                                                Tipo:
                                            </strong> 
                                                {{ details.sale_data[0].type == 'sale' ? 'Venta' : details.sale_data[0].type == 'reserve' ? 'Apartado' : details.sale_data[0].type == 'sale-pricing' ? 'Cotización Venta': details.sale_data[0].type == 'reserve-pricing' ?  'Cotización Apartado' : '' }}
                                        </span>
                                    </div>
                                    <div class="col-3">
                                        <span><strong>Descuento:</strong> {{ details.sale_data[0].discount_pct }}</span>
                                    </div>
                                    <div class="col-3">
                                        <span><strong>IVA:</strong> ${{ details.sale_data[0].iva }}</span>
                                    </div>
                                    <div class="col-3">
                                        <span><strong>Subtotal:</strong> ${{ details.sale_data[0].subtotal }}</span>
                                    </div>
                                    <div class="col-3">
                                        <span><strong>Total</strong> ${{ details.sale_data[0].total }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 my-2">
                                <h6 class="opensans-bold">
                                    Detalles del pago
                                </h6>
                            </div>

                            <div class="col-12 my-2">
                                <div class="row" v-for="(payment, index) in details.payment_details" :key="`index${index}`">
                                    <div class="col-12">
                                        <span>
                                            <strong>Método de pago: </strong> {{ payment.transaction_name }}
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span>
                                            <strong>Cantidad: </strong> $ {{ payment.amount }}
                                        </span>
                                    </div>
                                    <div class="col-4" v-if="payment.transaction_name === 'EFECTIVO'">
                                        <span>
                                            <strong>Cantidad recibida: </strong> $ {{ payment.money_received }}
                                        </span>
                                    </div>
                                    <div class="col-4" v-if="payment.transaction_name === 'EFECTIVO'">
                                        <span>
                                            <strong>Cambio: </strong> $ {{ payment.effective_change }}
                                        </span>
                                    </div>
                                    <div class="col-4" v-if="payment.transaction_name !== 'EFECTIVO'">
                                        <span>
                                            <strong>Tarjeta: </strong> {{ payment.numbers_card }}
                                        </span>
                                    </div>
                                    <div class="col-4" v-if="payment.transaction_name !== 'EFECTIVO'">
                                        <span>
                                            <strong>Código Autorización: </strong> {{ payment.authorization_code }}
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 my-2">
                                <h6 class="opensans-bold">
                                    Lista de Productos
                                </h6>
                            </div>
                            <!--Product list-->
                            <div class="col-12 my-2">
                                <ki-table :data="details.product_list" 
                                        :allow_headers="product_list_headers" 
                                        color_headers_text="white" border_headers="primary"
                                        no_pagination color_headers="primary" color_body="white" striped>
                                        <template v-slot:price_type="data">
                                            {{ data.item.price_type == 'retail_price' ? 'Minorista' : 'Mayorista'}}
                                        </template>
                                </ki-table>
                            </div>

                        </div>
                    </ki-modal>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    //helpers
    import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            headers_allow: [
                { key: 'store_name', label: "Sucursal" }, 
                { key: 'dc_name', label: "Almacen" },
                { key: 'type', label: "Tipo"},
                { key: 'iva', label: "IVA"},
                { key: 'subtotal', label: "Subtotal"},
                { key: 'total', label: "Total"},
                { key: 'creation', label: "Fecha"},
                { key: 'details'}
            ],
            product_list_headers: [
                {key: 'id_product', label: 'ID'},
                {key: 'product_name', label: 'Producto'},
                {key: 'upc', label: 'Código'},
                {key: 'discount_pct', label: '% Descuento'},
                {key: 'discount', label: 'Descuento'},
                {key: 'price', label: 'Precio'},
                {key: 'quantity', label: 'Cantidad'},
                {key: 'price_type', label: 'Tipo de precio'},
                {key: 'iva', label: 'IVA'},
                {key: 'subtotal', label: 'Subtotal'},
                {key: 'total', label: 'Total'},
            ],
            data_table: [],
            loading: true,
            loading_error: null,
            empty_search: false,
            detail_modal: false,
            init_date: this.dateParser(Date.now()),
            end_date: this.dateParser(Date.now()),
            filter_type: '',
            clients_options: [],
            vendors_options: [],
            category_options: [],
            subcategory_options: [],
            products_options: [],
            options: [],
            id_type: null,
            //paginator
            current_page: 1,
            rows: 10,
            search_query: null
        };
    },
    computed: {
        ...mapGetters({
            total_pages: 'reports/getTotalPages',
            total_rows: 'reports/getTotalRows',
            sales: 'reports/getCollection',
            details: 'reports/getDetails',
            clients: 'clients/getClients',
            categories: 'category/getCategories',
            subcategories: 'subcategory/getSubcategories',
            products: 'products_module/getProducts',
            vendors: 'providers/getProviders'
        })
    },
    methods: {
        //paginator
        async navigate(){
            this.loading = true
            this.empty_search = false

            let query = {
                datatable: true,
                page: this.current_page, 
                rows: this.rows, 
                date_init: this.init_date,
                date_end: this.end_date,
                id_client: null,
                id_vendor: null,
                id_category: null,
                id_subcategory: null,
                id_product: null,
                search: ''
            }

            if(this.filter_type) {
                switch(this.filter_type) {
                    case 'clients':
                        query.id_client = this.id_type
                        break;
                    case 'vendors':
                        query.id_vendor = this.id_type
                        break;
                    case 'category':
                        query.id_category = this.id_type
                        break;
                    case 'subcategory':
                        query.id_subcategory = this.id_type
                        break;
                    case 'product':
                        query.id_product = this.id_type
                        break;
                    default:
                        console.log('invalid filter type');
                        return;
                }
            }


            if(this.rows > this.total_rows) {
                this.rows = this.total_rows == 0 ? 10 : this.total_rows;

            }

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }

            if(this.search_query) {
                query.search = this.search_query;
                await this.$store.dispatch('reports/listSalesHistory', query)
            }

            else {
                await this.$store.dispatch('reports/listSalesHistory', query)
            }
            
            if(!this.sales) {
                this.empty_search = true;
            }

            

            else {
                this.data_table = this.sales.map((sale) => {
                    return {
                        ...sale,
                        details: '-'
                    }
                })
            }
            this.loading = false
            
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        },
        //helper
        async checkDetails(id) {
            await this.$store.dispatch('reports/saleDetails', id);
            this.detail_modal = true;
        },
        filterOptions() {
            switch(this.filter_type) {
                case '':
                    this.options = [];
                    break;
                case 'clients':
                    this.options = this.clients_options;
                    break;
                case 'vendors':
                    this.options = this.vendors_options;
                    break;
                case 'category':
                    this.options = this.category_options;
                    break;
                case 'subcategory':
                    this.options = this.subcategory_options;
                    break;
                case 'product':
                    this.options = this.products_options;
                    break;
                default:
                    console.log('No valid filter type');
                    break;
            }
        }

    },
    async mounted() {
        try {
            //list of sales
            await this.navigate()
            
            //clients options
            await this.$store.dispatch('clients/listClients', { datatable: false})
            if(this.clients != null) {
                this.clients_options = this.clients.map( client => {
                    return { id: client.id_client, label: client.client_name}
                });
            }

            //products
            await this.$store.dispatch('products_module/listProducts', {datatable: false})
            if(this.products != null) {
                this.products_options = this.products.map((product) => {
                    return {
                        id: product.id_product,
                        label: product.product_name
                    }
                })
            }
            
            //vendors
            await this.$store.dispatch('providers/listProviders', { datatable: false})
            if(this.vendors != null) {
                this.vendors_options = this.vendors.map((vendor) => {
                    return {
                        id: vendor.id_vendor,
                        label: vendor.vendor_name
                    }
                })
            }

             //subcategories options
            await this.$store.dispatch('subcategory/listSubcategories', {datatable: false})
            if(this.subcategories != null) {
                this.subcategory_options = this.subcategories.map(subcategory => { 
                    return { 
                        id: subcategory.id_sub_category, 
                        label: subcategory.sub_category_name
                    }
                })
            }

            //opciones de categorias
            await this.$store.dispatch('category/listCategories', {datatable: false})
            if(this.categories != null) {
                this.category_options = this.categories.map( category => {

                    return { 
                        id: category.id_category, 
                        label: category.category_name 
                    }
                })
            }
            
            

            this.loading = false
        }

        catch(e) {
            this.loading = false
            this.loading_error = this.getError
        }
        
    }
};
</script>