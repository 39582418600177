<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box">
        <div class="col-12 p-0">
            <span class="text-black opensans-semibold ki--description-sm">Reporte de corte de caja simplificado</span>
        </div>
        <div class="col-12 p-0">
            <div class="row bg_gray px-0 py-2">
                <div class="col-12 p-0">
                    <div class="row p-0">
                        <div class="col-2 p-0">
                            <ki-input label="Desde:"
                                v-model="start_date"
                                type="date"
                                placeholder="00/00/00"
                                class="opensans-bold pt-1 pl-2"
                                full>
                            </ki-input>
                        </div>
                        <div class="col-2 p-0">
                            <ki-input label="Hasta:"
                                v-model="end_date"
                                placeholder="00/00/00"
                                type="date"
                                class="opensans-bold pt-1 pl-2"
                                full>
                            </ki-input>
                        </div>
                        <div class="col p-0">
                            <div class="h-100 d-flex justify-content-start align-items-end pl-2">
                                <button class="pills bg-white px-4">Generar reporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 mt-2">
            <ki-table :data="data_table"
                :allow_headers="headers"
                no_pagination color_headers="white" color_body="white">

            </ki-table>
        </div>
        <div class="col-12 p-0 mt-3">
            <div class="row p-0 col d-flex justify-content-end align-items-center">
                <div>
                    <button class="pills bg-primary-dark px-4 text-white">Imprimir reporte</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ki-boxcut-reports',

    data() {
        return {
            headers: [
                {key: 'code', label: 'Código', width: 100},
                {key: 'product', label: 'Producto'},
                {key: 'quantity', label: 'Cantidad', width: 100},
                {key: 'price', label: 'Precio', width: 100},
                {key: 'regular_price', label: 'Precio regular', width: 150}
            ],
            data_table: [
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'},
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'},
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'},
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'},
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'},
                { code: '-', product: '-', quantity: '-', price: '-', regular_price: '-'}
            ],
            start_date: '2022-06-15',
            end_date: '2022-06-20'
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>