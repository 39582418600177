<template>
    <div class="container-fluid bg-white shadow-sm p-3 mb-5 rounded border-box">
        <div class="col-12 p-0">
            <span class="text-black opensans-semibold ki--description-sm">Reporte de cambio de mercancia</span>
        </div>
        <div class="col-12 p-0">
            <div class="row bg_gray px-0 py-2">
                <div class="col-12 p-0">
                    <div class="row p-0">
                        <div class="col-2 p-0">
                            <ki-input label="Desde:"
                                type="date"
                                v-model="start_date"
                                placeholder="00/00/00"
                                class="opensans-bold pt-1 pl-2"
                                full>
                            </ki-input>
                        </div>
                        <div class="col-2 p-0">
                            <ki-input label="Hasta:"
                                type="date"
                                v-model="end_date"
                                placeholder="00/00/00"
                                class="opensans-bold pt-1 pl-2"
                                full>
                            </ki-input>
                        </div>
                        <div class="col p-0">
                            <div class="h-100 d-flex justify-content-start align-items-end pl-2">
                                <button class="pills bg-white px-4">Generar reporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  v-for="table, index in 3" :key="index"
            class="col-12 p-0 mt-2 border-table">
            <ki-table :data="data_change_table"
                :allow_headers="change_table_headers"
                no_pagination color_headers="success" color_body="success">
            </ki-table>
            <ki-table :data="data_description_table"
                :allow_headers="description_table_headers"
                border_headers="0"
                border_body="0"
                 no_pagination color_headers="white" color_body="white">

            </ki-table>
        </div>
        <div class="col-12 p-0 mt-3">
            <div class="row p-0 col d-flex justify-content-end align-items-center">
                <div>
                    <button class="pills bg-primary-dark px-4 text-white">Imprimir reporte</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ki-warechanges-reports',

    data() {
        return {
            change_table_headers: [
                {key: 'change', label: 'Cambio No.', width: 150},
                {key: 'date', label: 'Fecha'},
                {key: 'user', label: 'Usuario', width: 150},
                {key: 'total', label: 'Total', width: 150},
            ],
            data_change_table: [
                { change: '-', date: '-', user: '-', total: '-'}
            ],
            description_table_headers: [
                {key: 'quantity', label: 'Cantidad', width: 150},
                {key: 'product', label: 'Producto'},
                {key: 'price', label: 'Precio', width: 150},
                {key: 'type', label: 'Tipo', width: 150}
            ],
            data_description_table: [
                {quantity: '-', product: '-', price: '-', type: 'Entrada'},
                {quantity: '-', product: '-', price: '-', type: 'Salida'}
            ],
            start_date: '2022-06-15',
            end_date: '2022-06-20'
        }
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
</style>