<template>
    <div id="config-discount" class="container-fluid">
        <div class="row pb-4">
            <div class="col-12 bg-white shadow-sm py-3 px-3 rounded border-box">
                <div class="row p-0">

                    <div class="col-12 px-0 py-3 bg-lightgray my-2">
                        <div class="row p-0">

                            <div class="col-12 col-md-6 col-xl-4 opensans-bold">
                                <ki-input @input="navigate"
                                      label="Buscar.."
                                      placeholder="Ej: promoción..."
                                      v-model="search_query"
                                      full>
                                </ki-input>
                            </div>

                            <div class="col-12 col-md-3 col-xl-4 opensans-bold">
                                <ki-input @change="navigate"
                                      label="Fecha inicial"
                                      type="date"
                                      v-model="init_date"
                                      full>
                                </ki-input>
                            </div>

                            <div class="col-12 col-md-3 col-xl-4 opensans-bold">
                                <ki-input @change="navigate"
                                      label="Fecha Final"
                                      type="date"
                                      v-model="end_date"
                                      full>
                                </ki-input>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 py-3" v-if="loading">
                        <ki-loader></ki-loader>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="!loading && loading_error">
                        <p>
                            Un error ha ocurrido intente más tarde
                        </p>
                    </div>

                    <div class="col-12 text-dark" v-if="empty_search">
                        <p>
                            No existen elementos con esos párametros de búsqueda
                        </p>
                    </div>

                    <div class="col-12 text-dark text-center opensans-bold" v-if="!loading && !empty_search && data_table.length == 0">
                        <p>
                            No existen elementos para la fecha indicada
                        </p>
                    </div>

                    <div class="col-12 p-0 mt-2" v-if="!loading && !empty_search && data_table.length > 0">
                        <ki-table :data="data_table"
                                  :allow_headers="headers_allow"
                                  no_pagination color_headers="white" color_body="white">

                            <template v-slot:type_transfer="data">
                                {{ data.item.type === 'between_dc' ? 'Entre Almacenes' : 'hacia Almacén' }}
                            </template>
                        </ki-table>
                    </div>

                    <!--- Paginator ----->
                    <div class="col-12 p-0 m-0 mt-2" v-if="!loading && !empty_search && data_table.length > 0">
                        <div class="row justify-content-between align-items-center text-dark">
                            <div class="col d-flex">
                                <label class="mr-2">ventas por página: </label>
                                <ki-input @keyup.enter="navigate"
                                          v-model="rows"
                                          :disabled="current_page > 1">
                                </ki-input>
                            </div>

                            <div class="col-1  d-flex justify-content-end p-0">
                                <button @click.stop="prev"
                                        class="btn btn-primary text-white pills"
                                        type="button"
                                        :disabled="current_page == 1">
                                    <b-icon icon="arrow-left"
                                            variant="white">
                                    </b-icon>
                                </button>
                            </div>

                            <div class="col-1" style="max-width: 80px;">
                                <ki-input @keyup.enter="navigate"
                                          v-model="current_page"
                                          full>
                                </ki-input>
                            </div>

                            <div class="col-1">
                                <p class="m-0">
                                    de {{  total_pages  }}
                                </p>
                            </div>

                            <div class="col-1 p-0" style="max-width: 65px">
                                <button @click.stop="next"
                                        class="btn btn-primary text-white pills"
                                        :disabled="current_page == total_pages"
                                        type="button">
                                    <b-icon icon="arrow-right"
                                            variant="white">
                                    </b-icon>
                                </button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>

//helpers
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            headers_allow: [
                {key: 'id_history_transfer', label: '#'},
                {key: 'dc_name_origin', label: 'Origen'},
                {key: 'dc_name_destination', label: 'Destino'},
                {key: 'product_name', label: 'Producto'},
                {key: 'quantity', label: 'Cantidad'},
                {key: 'type_transfer', label: 'Tipo'},
            ],
            data_table: [],
            loading: true,
            loading_error: null,
            empty_search: false,
            detail_modal: false,
            init_date: this.dateParser(Date.now()),
            end_date: this.dateParser(Date.now()),
            filter_type: '',
            clients_options: [],
            vendors_options: [],
            category_options: [],
            subcategory_options: [],
            products_options: [],
            options: [],
            id_type: null,
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            total_pages: 'reports/getTotalPages',
            total_rows: 'reports/getTotalRows',
            transfers: 'reports/getCollection',
        })
    },
    methods: {
        //paginator
        async navigate() {
            this.loading = true
            this.empty_search = false

            let query = {
                datatable: true,
                page: this.current_page,
                rows: this.rows,
                date_init: this.init_date,
                date_end: this.end_date,
                search: '',
                type: 'between_dc'
            }


            if (this.rows > this.total_rows) {
                this.rows = this.total_rows == 0 ? 10 : this.total_rows;

            }

            if (this.current_page > this.total_pages) {
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }


            query.search = this.search_query;
            await this.$store.dispatch('reports/listTransfersHistory', query)


            if (!this.transfers) {
                this.empty_search = true;
            } else {
                this.data_table = this.transfers.map((sale) => {
                    return {
                        ...sale,
                        details: '-'
                    }
                })
            }
            this.loading = false

        },
        prev() {
            this.current_page--;
            this.navigate()
        },
        next() {
            this.current_page++;
            this.navigate()
        },

    },
    async mounted() {
        try {
            //list of sales
            await this.navigate()

            this.loading = false
        }

        catch(e) {
            this.loading = false
            this.loading_error = this.getError
        }

    }
};
</script>